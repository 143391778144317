<ng-container *ngIf="asset">
    <div
        class="list-view"
        (click)="onClick()"
        [ngClass]="{ selected: isSelected, 'image-asset': isImage || isPdf, 'excel-asset': isExcel }"
        *ngIf="isListView; else gridView">
        <div class="inner">
            <div class="column-title">
                <elias-asset-thumbnail [asset]="asset" iconSize="small"></elias-asset-thumbnail>
                <h4 class="name">
                    {{ asset.name | strlen : 255 }}
                </h4>

                <i class="ei ei-error-full error-icon" *ngIf="hasErrors"></i>
                <i class="ei ei-processing-full processing-icon" *ngIf="isBeingProcessed"></i>
            </div>

            <div class="column-tags">
                <div class="tags" fxLayout="row wrap" fxLayoutGap="6px">
                    <div
                        class="tag dots"
                        *ngFor="let assetCollection of asset.assetCollections"
                        [ngStyle]="{
                            'background-color': assetCollectionsQuery.getEntity(assetCollection)?.color
                        }"
                        matTooltip="{{ assetCollectionsQuery.getEntity(assetCollection)?.name }}"></div>
                </div>
            </div>

            <div class="column-date">
                <div class="date">{{ asset.updatedAt | localizedDate : 'eliasMediumDate' }}</div>
            </div>

            <button mat-flat-button color="accent" class="edit-list-button">
                <i class="ei ei-edit"></i>
            </button>
        </div>
    </div>

    <ng-template #gridView>
        <div
            class="asset-file grid-view"
            [ngClass]="{ selected: isSelected, 'image-asset': isImage || isPdf, 'excel-asset': isExcel }">
            <div
                class="file"
                (click)="onClick()"
                [ngClass]="{
                    selectable: isSelectable,
                    styling: !isImage
                }">
                <div class="icons-container">
                    <i class="ei ei-error-full error-icon" *ngIf="hasErrors"></i>
                    <i class="ei ei-processing-full processing-icon" *ngIf="isBeingProcessed"></i>
                    <i class="ei ei-done done" *ngIf="isSelected"></i>
                </div>
                <elias-asset-thumbnail [asset]="asset" iconSize="medium"></elias-asset-thumbnail>
                <div class="name">
                    {{ asset.name }}
                </div>
            </div>
        </div>
    </ng-template>
</ng-container>
