<div class="chart">
    <div *ngIf="isLoading$ | async">
        <elias-shared-loading [centered]="true" descriptionContext="chart"></elias-shared-loading>
    </div>

    <img
        *ngIf="content$ | async as path; else chartNotGenerated"
        [ngClass]="{ invisible: isLoading$ | async }"
        [src]="path" />

    <ng-template #chartNotGenerated>
        <elias-status-message
            type="warning"
            *ngIf="isGenerating$ | async; else chartError"
            [ngClass]="{ invisible: isLoading$ | async }">
            {{ 'error.chartTooManyRetries' | translate }}
            <i action class="ei ei-refresh refresh-icon" (click)="reloadChart($event)"></i>
        </elias-status-message>
    </ng-template>

    <ng-template #chartError>
        <elias-status-message type="error" [ngClass]="{ invisible: isLoading$ | async }">
            {{ 'error.chartError' | translate }}
            <i action class="ei ei-refresh refresh-icon" (click)="regenerateChart($event)"></i>
        </elias-status-message>
    </ng-template>
</div>
