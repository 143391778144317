interface HistoryIcons {
    [type: string]: { [subtype: string]: string };
}

export const HistoryIconsMap: HistoryIcons = {
    node: {
        'accumulated-bar-chart': 'tools-graphs-stacked-bar',
        'bar-chart': 'tools-graphs-bar',
        'bubble-chart': 'tools-graphs-bubble',
        'col-break': 'tools-column-break-left',
        disclaimer: 'tools-disclaimer',
        'excel-table': 'file-xls',
        footnote: 'tools-footnote',
        image: 'tools-image',
        'image-grid': 'tools-image-gallery',
        'key-figure': 'tools-key-figure',
        lead: 'tools-lead',
        line: 'divider',
        'line-chart': 'tools-graphs-line',
        'map-chart': 'tools-graphs-map',
        'page-break': 'tools-page-break',
        'paired-bar-chart': 'tools-graphs-paired-bar',
        'paired-bar-line-chart': 'tools-graphs-paired-bar-line',
        'pie-chart': 'tools-graphs-pie',
        quote: 'tools-citation',
        table: 'tools-table',
        'table-of-content': 'tools-toc',
        text: 'tools-text',
        title: 'tools-title',
        'two-cols-end': 'tools-columns-end',
        'two-cols-start': 'tools-columns-start',
    },
    section: { general: 'section', 'back-cover': 'section', title: 'section', inverted: 'section' },
    publication: { '': 'publication' },
};
