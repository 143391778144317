import { Component, Input, OnInit } from '@angular/core';

type MessageType = 'notice' | 'warning' | 'error';

@Component({
    selector: 'elias-status-message',
    templateUrl: './status-message.component.html',
    styleUrls: ['./status-message.component.scss'],
})
export class StatusMessageComponent {
    @Input() type: MessageType = 'notice';
}
