<elias-status-message type="warning" *ngIf="isOutdated; else excelTable">
    {{ 'error.excelTable.outdated' | translate }}
</elias-status-message>

<ng-template #excelTable>
    <div
        *ngIf="hasRenderedContent && hasData; else error"
        class="e-table-container"
        [innerHTML]="renderedContent | html"></div>
</ng-template>

<ng-template #error>
    <elias-status-message type="warning">
        {{ 'component.table.missing' | translate }}
    </elias-status-message>
</ng-template>
