import { AfterViewInit, Component, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Node } from '../../models/node/node.model';
import { SelectedNode } from '../../models/selected-element.model';
import { DeleteComponent } from '../../../../modules/shared/dialogs/delete/delete.component';
import { NodeDto } from '../../models/dto/node-dto.model';
import { NodesService } from '../../services';
import { SaveService } from '../../services/save.service';
import { NodesQuery } from '../../state/nodes/nodes.query';
import { NodesStore } from '../../state/nodes/nodes.store';
import { MatDialog } from '@angular/material/dialog';
import { NodeViewModel } from '../../viewmodels/node.viewmodel';
import { DataService } from '../../services/data.service';
import { EditableNodeComponent, NodeDeselectedEvent } from '../node-editable/node-editable.component';
import { SectionsQuery } from '../../state/sections/sections.query';

@Component({
    selector: 'elias-document-nodes',
    templateUrl: './document-nodes.component.html',
    styleUrls: ['./document-nodes.component.scss'],
})
export class DocumentNodesComponent implements AfterViewInit, OnDestroy {
    @ViewChildren(EditableNodeComponent) editableNodes?: QueryList<EditableNodeComponent>;

    public active$: Observable<number>;
    public nodes$: Observable<Node[]>;
    public selectedNode$: Observable<Node | undefined>;
    public isLoading = false;

    private destroy$ = new Subject<void>();

    constructor(
        public dialog: MatDialog,
        private dataService: DataService,
        private nodesQuery: NodesQuery,
        private nodesService: NodesService,
        private nodesStore: NodesStore,
        private nodeViewModel: NodeViewModel,
        private saveService: SaveService,
        private sectionsQuery: SectionsQuery
    ) {
        this.nodes$ = this.nodesQuery.selectAll();
        this.selectedNode$ = this.nodesQuery.select('selectedNode');
        this.active$ = this.nodesQuery
            .selectAll({
                filterBy: (entity) => entity.editing,
            })
            .pipe(map((node) => node.length));
    }

    ngAfterViewInit() {
        this.nodesQuery
            .select('scrollToNodeId')
            .pipe(takeUntil(this.destroy$))
            .subscribe((nodeId) => {
                if (nodeId) {
                    this.scrollToNode(nodeId);
                }
            });
    }

    public onSelect(event: MouseEvent, node: Node): void {
        event.stopPropagation();

        if (node.editing) {
            return;
        }

        this.nodesService.selectNode(new SelectedNode(node));

        if (this.nodesQuery.hasEntity('temp-100')) {
            this.nodesStore.remove('temp-100');
        }
    }

    public onDeselect({ node, isDirty }: NodeDeselectedEvent): void {
        if (isDirty) {
            this.saveService.saveNode(node).subscribe(() => {
                this.nodesService.deselect(node);
            });
        } else {
            this.nodesService.deselect(node);
        }
    }

    public onRemove(node: Node): void {
        const dialogRef = this.dialog.open(DeleteComponent, {
            data: {
                type: 'node',
            },
        });

        dialogRef.afterClosed().subscribe((remove) => {
            if (remove) {
                const payload: NodeDto = new NodeDto({ nodeId: node.id });
                this.nodesService.deleteNode(payload).subscribe();
            }
        });
    }

    public onContentChange(content: string): void {
        this.nodeViewModel.updateContent(content);
    }

    public async selectedOption($event: { type: any; content: string }): Promise<void> {
        let nodeInfo: any;
        this.dataService.data$.subscribe((data) => (nodeInfo = data));
        const position = nodeInfo.pos;
        const source = nodeInfo.src;

        const params = {
            sectionId: this.sectionsQuery.getActiveId(),
            type: $event.type,
        };

        const queryParams = { position };
        const content = $event.content || '';
        const body = { content };
        const payload = new NodeDto(params, queryParams, body);

        await this.nodesQuery.waitUntilAllSaved();
        this.nodesService.createNode(payload).subscribe(() => {
            this.nodesStore.remove('temp-100');
            this.isLoading = false;
        });

        this.isLoading = true;

        if (source) source.classList.remove('active');
    }

    public removeOptions(): void {
        this.nodesStore.remove('temp-100');
    }

    /**
     * This is important to avoid re-rendering node components everytime store is updated.
     */
    public trackNodesById(index: number, node: Node): string {
        return node.id;
    }

    private getEditableNodeComponentById(id: string): EditableNodeComponent | undefined {
        return this.editableNodes?.find((component) => component.node.id === id);
    }

    private scrollToNode(nodeId: string): void {
        const nodeEditableComponent = this.getEditableNodeComponentById(nodeId);
        if (!nodeEditableComponent) {
            return;
        }

        const nativeElement = nodeEditableComponent.elementRef.nativeElement;
        nativeElement.scrollIntoView();

        this.nodesStore.update({ scrollToNodeId: undefined });
    }

    ngOnDestroy(): void {
        this.destroy$.complete();
    }
}
