import { Store, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Language } from '../../models/language.model';
import { LayoutConfiguration } from '../../models/layout-configuration.model';

export interface SystemInformationState {
    clientVersion: string | null;
    deletedPublicationAssetsRemoveTime: number;
    domain: string;
    emailAddress: string;
    emailName: string;
    featureFlags: { [key: string]: boolean };
    languages: Language[];
    layouts: { [k: string]: LayoutConfiguration };
    loaded: boolean;
    loading: boolean;
    maximumUploadSize: number;
    nodesSupportedForVersioning: string[];
    passwordPolicyExplanation: string;
    passwordPolicyPattern: string;
    phpWord: string;
    publicAssetsDomain: string;
    renderer: any[];
    sectionLockExpiringTime: number;
    smsAccountName: string;
    smsEnabled: boolean;
    twoFactorAuthMethods: any[];
    updateInterval: number;
    validPhoneNumberPattern: string;
    version: string;
    webViewerUrl: string;
}

export function createInitialState() {
    return {
        clientVersion: null,
        deletedPublicationAssetsRemoveTime: undefined,
        domain: undefined,
        emailAddress: undefined,
        emailName: undefined,
        featureFlags: {},
        languages: [],
        layouts: {},
        loaded: false,
        loading: false,
        maximumUploadSize: undefined,
        nodesSupportedForVersioning: [],
        passwordPolicyExplanation: undefined,
        passwordPolicyPattern: undefined,
        phpWord: undefined,
        publicAssetsDomain: undefined,
        renderer: [],
        sectionLockExpiringTime: undefined,
        smsAccountName: undefined,
        smsEnabled: false,
        twoFactorAuthMethods: [],
        updateInterval: undefined,
        validPhoneNumberPattern: undefined,
        version: undefined,
        webViewerUrl: undefined,
    };
}

@Injectable({
    providedIn: 'root',
})
@StoreConfig({ name: 'SystemInformation' })
export class SystemInformationStore extends Store<SystemInformationState> {
    constructor() {
        super(createInitialState());
    }
}
