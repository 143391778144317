import { AfterViewInit, Component, Input } from '@angular/core';
import { NodeRepresentation } from '../../../interfaces/node-representation.interface';
import { Observable } from 'rxjs';
import { NodeConfig } from '../../../models/node/node-type.model';
import { ExcelChartNode } from '../../../models/node/excel-chart-node.model';
import { PropertyEditorComponent } from '../../property-editor/property-editor.component';
import { SectionsQuery } from '../../../state/sections/sections.query';
import { SideNavService } from '../../../../../modules/core/services/side-nav.service';

const SAMPLE_FILENAMES: { [k: string]: string } = {
    'excel-pie-chart': 'eliasPublisher - Pie Chart TEMPLATE',
    'excel-line-chart': 'eliasPublisher - Line Chart TEMPLATE',
    'excel-bar-chart': 'eliasPublisher - Bar Chart TEMPLATE',
    'excel-bubble-chart': 'eliasPublisher - Bubble Chart TEMPLATE',
    'excel-map-chart': 'eliasPublisher - Map Chart TEMPLATE',
    'excel-paired-bar-chart': 'eliasPublisher - Paired Bar Chart TEMPLATE',
    'excel-paired-bar-line-chart': 'eliasPublisher - Combined Bar Line Chart TEMPLATE',
    'excel-accumulated-bar-chart': 'eliasPublisher - Accumulated Bar Chart TEMPLATE',
};

@Component({
    selector: 'elias-editor-display-excel-chart',
    templateUrl: './display-excel-chart.component.html',
    styleUrls: ['display-excel-chart.component.scss'],
})
export class DisplayExcelChartComponent implements NodeRepresentation, AfterViewInit {
    @Input() config?: NodeConfig;
    @Input() content = '';
    @Input() node?: ExcelChartNode;
    @Input() nodeViewModel?: Observable<ExcelChartNode>;

    get hasExcelAndWorksheetAssigned(): boolean {
        return !!(this.node && this.node.excelAsset && this.node.excelWorksheet);
    }

    get isOutdated(): boolean {
        return !!this.node?.outdated;
    }

    get sampleFilename(): string {
        if (!this.node) {
            return '';
        }

        const type = this.node.type;
        if (!(type in SAMPLE_FILENAMES)) {
            throw new Error(`Invalid excel chart node type: ${type}`);
        }

        return SAMPLE_FILENAMES[type];
    }

    constructor(private sectionsQuery: SectionsQuery, private sideNavService: SideNavService) {}

    ngAfterViewInit(): void {
        if (!this.node) {
            return;
        }

        if (this.node.editing && !this.hasExcelAndWorksheetAssigned) {
            const inputs = {
                sectionOrNodeType: 'node',
                sectionId: this.sectionsQuery.getActiveId(),
                nodeId: this.node.id,
            };

            const outputs = {};
            this.sideNavService.setComponent(PropertyEditorComponent, inputs, outputs);
        }
    }
}
