import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Channel } from '../../../../modules/shared/models/channel.model';
import { Publication } from '../../../../publication/models/publication.model';
import { PublicationsQuery } from '../../../../publication/state/publications/publications.query';
import { PreviewDto } from '../../models/dto/preview-dto.model';

@Component({
    selector: 'elias-editor-preview-button',
    templateUrl: './preview-button.component.html',
    styleUrls: ['preview-button.component.scss'],
})
export class PreviewButtonComponent implements OnInit {
    @Input() channels: Channel[] = [];
    @Input() generating: boolean = false;

    @Output() generate = new EventEmitter<PreviewDto>();

    defaultChannel: Channel;
    publication: Publication;
    channelLength = 0;

    constructor(private publicationsQuery: PublicationsQuery) {}

    ngOnInit() {
        const publication = this.publicationsQuery.getActive() as Publication;

        this.channelLength = Object.keys(publication.channelSettings).filter(
            (key) => publication.channelSettings[key]['active'] && publication.channelSettings[key]['channel'].enabled
        ).length;

        for (const key of Object.keys(publication.channelSettings)) {
            if (publication.channelSettings[key]['active'] && publication.channelSettings[key]['channel'].enabled) {
                if (publication.channelSettings[key]['channel'].default) {
                    this.defaultChannel = publication.channelSettings[key]['channel'];
                    break;
                } else if (publication.channelSettings[key]['channel'].renderer === 'word') {
                    this.defaultChannel = publication.channelSettings[key]['channel'];
                } else {
                    this.defaultChannel = publication.channelSettings[key]['channel'];
                }
            }
        }

        this.publication = this.publicationsQuery.getActive() as Publication;
    }

    /**
     * Emit preview event. If no channel is selected, use first channel.
     *
     * @param channel
     */
    generatePreview(channel?: Channel): void {
        if (typeof channel === 'undefined') {
            channel = this.defaultChannel;
        }

        this.generate.emit({
            renderer: channel.renderer,
            channelId: channel.id,
        });
    }

    trackByIndex(index, item) {
        return index;
    }
}
