import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { RequestDto } from '../models/dto/request-dto.model';
import { DomainService } from '../../../modules/core/services/domain.service';
import { PreviewStore } from '../state/preview/preview.store';
import { Publication } from '../../../publication/models/publication.model';
import { Channel } from '../../../modules/shared/models/channel.model';
import * as dayjs from 'dayjs';
import { PreviewDto } from '../models/dto/preview-dto.model';

@Injectable()
export class PreviewService {
    constructor(private domainService: DomainService, private http: HttpClient, private previewStore: PreviewStore) {}

    /** GET preview word preview */
    getWordSectionPreview(payload: RequestDto): Observable<Blob> {
        this.previewStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/preview/section/${payload.getParam(
            'sectionId'
        )}/channel/${payload.getParam('channelId')}`;
        return this.http.get(url, { responseType: 'blob' }).pipe(
            catchError((error: any) => throwError(error)),
            finalize(() => {
                this.previewStore.setLoading(false);
            })
        );
    }

    /** GET html preview */
    getHtmlSectionPreview(payload: RequestDto): Observable<any> {
        this.previewStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/preview/section/${payload.getParam(
            'sectionId'
        )}/channel/${payload.getParam('channelId')}`;
        return this.http.get(url, { responseType: 'text' }).pipe(
            catchError((error: any) => throwError(error)),
            finalize(() => {
                this.previewStore.setLoading(false);
            })
        );
    }

    public createPublicationPreview(publication: Publication, channel: Channel): Observable<Blob> {
        const url = `${this.domainService.apiBaseUrl}/preview/publication/${publication.id}/channel/${channel.id}`;
        return this.http.get(url, {
            responseType: 'blob',
        });
    }

    public generateFileName(previewDto: PreviewDto, publication: Publication): string {
        const formattedDate = dayjs().format('YYYY-MM-DD');
        const title = publication.title ? `_${this.formatTitle(publication.title)}` : '';
        const subtitle = publication.subtitle ? `_${this.formatTitle(publication.subtitle)}` : '';
        const isRelease = publication.releases.hasOwnProperty(previewDto.channelId);
        const previewPart = isRelease ? '' : '_Preview';

        let extension: string;
        switch (previewDto.renderer) {
            case 'word':
                extension = 'docx';
                break;
            default:
                extension = 'html';
                break;
        }

        return `ELIAS_${formattedDate}${previewPart}${title}${subtitle}.${extension}`;
    }

    private formatTitle(title: string): string {
        return title.split(' ').join('_');
    }

    public downloadFile(publication: Publication, previewDto: PreviewDto, blob: Blob): void {
        const fileName = this.generateFileName(previewDto, publication);

        const blobType =
            previewDto.renderer === 'word'
                ? 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                : 'text/html';
        const newBlob: Blob = new Blob([blob], {
            type: blobType,
        });

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
            return;
        }

        const data = window.URL.createObjectURL(newBlob);
        const link = document.createElement('a');
        link.href = data;
        link.download = fileName;
        link.click();

        setTimeout(function () {
            window.URL.revokeObjectURL(data);
        }, 250);
    }
}
