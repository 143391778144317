import { Component, Input } from '@angular/core';
import { NodePreset } from '../../../../modules/shared/models/node-preset.model';
import { PublicationsQuery } from '../../../../publication/state/publications/publications.query';
import { SectionsQuery } from '../../state/sections/sections.query';
import { Section } from '../../models/section.model';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Publication } from '../../../../publication/models/publication.model';
import { LayoutService } from '../../services/layout.service';

interface PresetGroup {
    name: string;
    icon: string;
    data: NodePreset[];
}

@Component({
    selector: 'elias-editor-preset-browser',
    styleUrls: ['./preset-browser.component.scss'],
    templateUrl: './preset-browser.component.html',
})
export class PresetBrowserComponent {
    @Input() loading: boolean = true;
    @Input() nodePresets: NodePreset[] = [];

    public presetGroups$: Observable<PresetGroup[]>;

    constructor(
        private layoutService: LayoutService,
        private publicationsQuery: PublicationsQuery,
        private sectionsQuery: SectionsQuery
    ) {
        this.presetGroups$ = combineLatest([
            this.sectionsQuery.selectActive() as Observable<Section>,
            this.publicationsQuery.selectActive() as Observable<Publication>,
        ]).pipe(
            filter(([section, publication]) => !!section && !!publication),
            map(([section, publication]) => this.calculatePresetGroups(section, publication))
        );
    }

    private calculatePresetGroups(section: Section, publication: Publication): PresetGroup[] {
        const allowedNodeTypes = this.getAllowedNodeTypes(section, publication);
        const isNodeTypeAllowed = (type: string) => allowedNodeTypes.includes(type);

        const groupNames = this.nodePresets.reduce((acc: string[], curr: NodePreset) => {
            const next = isNodeTypeAllowed(curr.type) ? [curr.presetGroup] : [];

            return [...new Set([...acc, ...next])];
        }, []);

        return groupNames.map((name) => ({
            name,
            icon: this.formatIcons(name),
            data: this.nodePresets.filter((p) => p.presetGroup === name && isNodeTypeAllowed(p.type)),
        }));
    }

    private getAllowedNodeTypes(section: Section, publication: Publication): string[] {
        return this.layoutService.getAllowedNodeTypes(section.type, publication);
    }

    // TODO Use NodeTypes to get the icon directly
    private formatIcons(groupName: string): string {
        if (groupName === 'table') return 'ei-table';
        else if (groupName === 'text') return 'ei-tools-title';
        else if (groupName === 'structure') return 'ei-tools-page-break';
        else if (groupName === 'image') return 'ei-tools-image';
        else if (groupName === 'chart') return 'ei-bar-chart';
        else if (groupName === 'excel-chart') return 'ei-excel-bar-chart';

        return '';
    }
}
