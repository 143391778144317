<elias-status-message type="warning" *ngIf="isOutdated; else excelChart">
    {{ 'error.excelChart.outdated' | translate }}
</elias-status-message>

<ng-template #excelChart>
    <elias-editor-display-chart
        *ngIf="hasExcelAndWorksheetAssigned; else noWorksheetAssigned"
        [nodeViewModel]="nodeViewModel"
        [config]="config"
        [node]="node"
        [content]="content"></elias-editor-display-chart>
</ng-template>

<ng-template #noWorksheetAssigned>
    <elias-status-message type="warning">
        <span>{{ 'error.excelChart.noWorksheetAssigned' | translate }}</span>
        <a
            class="sample-link"
            download="{{ sampleFilename }}.xlsx"
            href="assets/excel-charts/{{ node?.type }}.xlsx"
            (click)="$event.stopPropagation()"
            data-cy="excel-sample-link"
            >{{ 'component.excelChart.sampleFile.link' | translate }}</a
        >
    </elias-status-message>
</ng-template>
