<ng-container *ngIf="images.length > 0">
    <div fxLayout="row wrap" fxLayoutGap="20px grid">
        <div class="grid-container" *ngFor="let image of objectData; trackBy: trackByIndex" [fxFlex]="100 / cols">
            <div>
                <ng-container *ngFor="let data of objectKeys(image); trackBy: trackByIndex">
                    <ng-container *ngIf="data !== 'link'">
                        <ng-container *ngIf="data !== 'image'; else dispImage">
                            <div
                                class="{{ data }}"
                                *ngIf="itemFieldSettings[data] && image[data]"
                                [innerHTML]="image[data] | html"></div>
                            <div
                                class="{{ data }}"
                                *ngIf="itemFieldSettings[data] && !image[data]"
                                [innerHTML]="'<p>&nbsp;</p>'"></div>
                        </ng-container>
                        <ng-template #dispImage>
                            <elias-shared-image-preview
                                [id]="image[data]"
                                [aspectRatio]="aspectRatio"></elias-shared-image-preview>
                        </ng-template>
                    </ng-container>
                </ng-container>
            </div>
            <div class="e-image-grid-bottom-line"></div>
        </div>
    </div>
</ng-container>
<div *ngIf="images.length === 0">
    <elias-status-message type="error">{{ 'component.imagegrid.missing' | translate }}</elias-status-message>
</div>
