import { Component, Input } from '@angular/core';
import { Asset } from '../../models/asset.model';
import { AssetsService } from '../../services/assets.service';

@Component({
    selector: 'elias-asset-thumbnail',
    templateUrl: './asset-thumbnail.component.html',
    styleUrls: ['./asset-thumbnail.component.scss'],
})
export class AssetThumbnailComponent {
    @Input() allowFallbackImage = false;
    @Input() asset?: Asset;
    @Input() iconSize: 'small' | 'medium' | 'big' = 'medium';

    constructor(private assetsService: AssetsService) {}

    get isImage(): boolean {
        if (!this.asset) {
            return false;
        }

        return this.assetsService.isImage(this.asset);
    }

    get isPdf(): boolean {
        if (!this.asset) {
            return false;
        }

        return this.assetsService.isPdf(this.asset);
    }

    get isExcel(): boolean {
        if (!this.asset) {
            return false;
        }

        return this.assetsService.isExcel(this.asset);
    }
}
