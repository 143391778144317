import { Injectable } from '@angular/core';
import { DomainService } from '../../../modules/core/services/domain.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ImagesStore } from '../../editor-container/state/images/images.store';
import { Observable, throwError } from 'rxjs';
import { ID, withTransaction } from '@datorama/akita';
import { catchError, map, tap } from 'rxjs/operators';
import { RequestDto } from '../../editor/models/dto/request-dto.model';
import { Asset } from '../../../modules/shared/models/asset.model';
import { AssetStorageType } from '../enums/asset-storage-type.enum';
import { Excel } from '../../editor/models/excel.model';
import { SectionUsingAsset } from '../../../modules/shared/models/section-using-asset.model';
import { AssetBrowserStore } from '../state/asset-browser/asset-browser.store';
import { ImagesService } from '../../editor-container/services/images.service';
import { Thumbnail } from '../../../modules/shared/models/thumbnail.model';
import { AssetBrowserQuery } from '../state/asset-browser/asset-browser.query';
import { PaginatedAssets } from '../models/paginated-assets.model';
import { ImageDto } from '../../../modules/shared/models/image-dto.model';
import { AssetsService } from '../../../modules/shared/services/assets.service';

@Injectable({
    providedIn: 'root',
})
export class AssetBrowserService {
    constructor(
        private assetsService: AssetsService,
        private assetBrowserStore: AssetBrowserStore,
        private assetBrowserQuery: AssetBrowserQuery,
        private domainService: DomainService,
        private http: HttpClient,
        private imagesStore: ImagesStore,
        private imagesService: ImagesService
    ) {}

    getAssetManagerAssets(publicationGroupId: ID, options: any = {}): Observable<PaginatedAssets> {
        this.assetBrowserStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/assets/publication-group/${publicationGroupId}`;
        return this.http.get<PaginatedAssets>(url, { params: options }).pipe(
            withTransaction((result: PaginatedAssets) => {
                this.assetBrowserStore.update({
                    currentPage: result.currentPage,
                    totalNumberOfPages: result.totalNumberOfPages,
                    totalDataLength: result.totalDataLength,
                });

                // Restore thumbnails from cache
                let assets: Asset[] = result.data;
                const thumbnails = this.assetBrowserQuery.getValue().thumbnails;
                for (let i = 0; i < assets.length; i++) {
                    const id = assets[i].id;
                    const thumbnail = thumbnails[id];

                    if (thumbnail) {
                        assets[i].thumbnail = thumbnail;
                    }
                }

                this.assetBrowserStore.set(assets);
                this.assetBrowserStore.setLoading(false);
                this.assetBrowserStore.update({ loaded: true });
            }),
            catchError((error: any) => throwError(error))
        );
    }

    loadSingleAsset(payload: RequestDto): Observable<Asset> {
        this.assetBrowserStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/assets/${payload.getParam('assetId')}`;

        return this.http.get<Asset>(url, {}).pipe(
            withTransaction((asset) => {
                if (asset.thumbnail) {
                    asset.thumbnail.content = URL.createObjectURL(
                        this.imagesService.b64toBlob(asset.thumbnail.content)
                    );

                    // Cache thumbnail
                    this.assetBrowserStore.update((state) => ({
                        ...state,
                        thumbnails: { ...state.thumbnails, [asset.id]: asset.thumbnail },
                    }));
                }

                this.assetBrowserStore.upsert(asset.id, asset);
                this.assetBrowserStore.setLoading(false);
            }),
            catchError((error: any) => throwError(error))
        );
    }

    getAssetBrowserThumbnails(imageIds: string[]): Observable<Thumbnail[]> {
        const ids = imageIds.join(',');

        return this.http.get<ImageDto[]>(`${this.domainService.apiBaseUrl}/thumbnails/multiple?ids=${ids}`).pipe(
            map((images) => {
                return images.map((image) => {
                    const type = image.type;
                    const content = URL.createObjectURL(this.imagesService.b64toBlob(image.content));

                    return { id: image.id, content, type };
                });
            }),
            tap((images: Thumbnail[]) => {
                images.forEach((thumbnail) => {
                    this.assetBrowserStore.update(thumbnail.id, { thumbnail });

                    // Cache thumbnails
                    this.assetBrowserStore.update((state) => ({
                        ...state,
                        thumbnails: { ...state.thumbnails, [thumbnail.id]: thumbnail },
                    }));
                });
            }),
            catchError((error: any) => {
                return throwError(error);
            })
        );
    }

    updateAsset(payload: RequestDto): Observable<Asset> {
        this.assetBrowserStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/assets/${payload.getParam('assetId')}`;
        return this.http.patch<Asset>(url, payload.body.data).pipe(
            withTransaction((asset) => {
                // Restore cached thumbnail
                const thumbnail = this.assetBrowserQuery.getValue().thumbnails[asset.id];
                if (thumbnail) {
                    asset.thumbnail = thumbnail;
                }

                this.assetBrowserStore.update(asset.id, asset);
                this.assetBrowserStore.setLoading(false);
            }),
            catchError((error: any) => throwError(error))
        );
    }

    deleteAsset(payload: RequestDto): Observable<Asset> {
        this.assetBrowserStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/assets/${payload.getParam('assetId')}`;

        return this.http.delete<Asset>(url, {}).pipe(
            withTransaction((asset) => {
                this.assetBrowserStore.remove(asset.id);
                this.assetBrowserStore.setLoading(false);

                if (this.isImage(asset)) {
                    this.imagesStore.remove(asset.id);
                }
            }),
            catchError((error: any) => throwError(error))
        );
    }

    downloadAsset(payload: RequestDto): Observable<any> {
        this.assetBrowserStore.setLoading(true);
        const assetId = payload.getParam('asset').id;
        const pubGroupId = payload.getQueryParam('pubGroupId');
        const url = `${this.domainService.apiBaseUrl}/assets/download/${assetId}/${pubGroupId}`;

        return this.http.get(url, { responseType: 'blob' }).pipe(
            tap((asset) => {
                this.assetBrowserStore.setLoading(false);
            }),
            catchError(this.parseErrorBlob)
        );
    }

    uploadAsset(payload: FormData, assetStorageType: AssetStorageType): Observable<any> {
        const url = this.getAssetUploadUrl(assetStorageType);

        return this.http.post(url, payload);
    }

    isImage(asset: Asset): boolean {
        return this.assetsService.isImage(asset);
    }

    isExcel(asset: Asset): asset is Excel {
        return this.assetsService.isExcel(asset);
    }

    parseErrorBlob(err: HttpErrorResponse): Observable<any> {
        const reader: FileReader = new FileReader();
        const obs = Observable.create((observer: any) => {
            reader.onloadend = (e) => {
                observer.error(JSON.parse(reader.result as string));
                observer.complete();
            };
        });
        reader.readAsText(err.error);
        return obs;
    }

    setFilter(filterType, filter) {
        const filters = {};
        filters[filterType] = filter;
        this.assetBrowserStore.update((assetState) => ({
            ui: { ...assetState.ui, filters: { ...assetState.ui.filters, ...filters } },
        }));
    }

    setSorting(sort) {
        this.assetBrowserStore.update((assetState) => ({
            ui: { ...assetState.ui, sort },
        }));
    }

    changeViewOption(viewOption: number) {
        this.assetBrowserStore.update((assetState) => ({
            ui: { ...assetState.ui, viewOption },
        }));
    }

    changeSearchField(search: string) {
        this.assetBrowserStore.update((assetState) => ({
            ui: { ...assetState.ui, search },
        }));
    }

    getSectionsUsingAsset(assetId: string): Observable<SectionUsingAsset[]> {
        const url = `${this.domainService.apiBaseUrl}/assets/${assetId}/sections`;

        return this.http.get<SectionUsingAsset[]>(url);
    }

    hasError(asset: Asset | undefined): boolean {
        if (!asset) {
            return false;
        }

        if (this.isExcel(asset)) {
            return asset.processingError;
        }

        return false;
    }

    private getAssetUploadUrl(assetStorageType: AssetStorageType): string {
        switch (assetStorageType) {
            case AssetStorageType.General:
                return `${this.domainService.apiBaseUrl}/assets`;

            case AssetStorageType.Shared:
                return `${this.domainService.apiBaseUrl}/assets/shared`;

            case AssetStorageType.PublicationGroup:
                return `${this.domainService.apiBaseUrl}/assets/publication-only`;

            default:
                return `${this.domainService.apiBaseUrl}/assets`;
        }
    }
}
